"use client";

import { ThemeProvider } from "next-themes";

import { SessionProvider } from "@/components/session";
import StoreProvider from "@/redux/provider";

export default function Providers({ children }: { children: React.ReactNode }) {
  return (
    <ThemeProvider attribute="data-theme" themes={["light", "dark"]}>
      <StoreProvider>
        <SessionProvider>{children}</SessionProvider>
      </StoreProvider>
    </ThemeProvider>
  );
}
