import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import { v4 as uuidv4 } from "uuid";

import { Project } from "./types";

export const projectsAdapter = createEntityAdapter<Project>();

const projectsSlice = createSlice({
  name: "projects",
  initialState: projectsAdapter.addOne(projectsAdapter.getInitialState(), {
    id: "test-project",
    name: "Test project",
    description: "A default project to use for debugging",
  }),
  reducers: {
    projectAdded: {
      reducer: projectsAdapter.addOne,
      prepare(project: Omit<Project, "id">) {
        const id = `project-${uuidv4()}`;
        const payload = { ...project, id };
        return { payload };
      },
    },
    projectRemoved: projectsAdapter.removeOne,
  },
});

export const { projectAdded, projectRemoved } = projectsSlice.actions;

export default projectsSlice;
