"use client";

import { ReactNode, createContext, useContext, useEffect, useState } from "react";

type Session = {
  user?: any;
};

const SessionContext = createContext<Session>({});

export function useSession(): Session {
  return useContext<Session>(SessionContext);
}

export function SessionProvider({ children }: { children?: ReactNode }): JSX.Element {
  const [session, setSession] = useState<Session>({});

  useEffect(() => {
    const onStorage = (event: StorageEvent) => {
      console.debug(event);
      if (event.storageArea === localStorage && event.key === "access_token") {
        const { oldValue: oldToken, newValue: newToken } = event;
        console.log(`New access token: ${oldToken} => ${newToken}`);
        // OpenAPI.TOKEN = newToken ?? undefined;
      }
    };
    window.addEventListener("storage", onStorage);
    return () => window.removeEventListener("storage", onStorage);
  }, []);

  return <SessionContext.Provider value={session}>{children}</SessionContext.Provider>;
}

export function signIn(accessToken: string): void {
  localStorage.setItem("access_token", accessToken);
  // OpenAPI.TOKEN = accessToken;
}

export function signOut(): void {
  localStorage.removeItem("access_token");
  // OpenAPI.TOKEN = undefined;
}
